.wrapper {
  justify-content: center;

  .number-container {
    span {
      font-size: 3.5rem;
      font-weight: 800;
      transition: color 800ms;

      &:not(last-child) {
        margin-right: 2rem;
      }
    }

    &__duration {
      width: 1.875rem;
      height: 0.125rem;
      background-color: #a7a6a6;

      &:not(last-child) {
        margin-right: 0.8rem;
      }

      &.animate-bg {
        position: relative;
        > div {
          position: absolute;
          inset: 0;
          animation: increase-width 3s linear;
          background-color: var(--primary-color);
        }
      }
    }
  }
}

.color-secondary {
  color: #c1c1c1;
}
