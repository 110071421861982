@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,800,900");
:root {
  --primary-color: #303189;
  --font-roboto: Roboto, sans-serif;
  --font-Helvetica: "Helvetica Neue", sans-serif;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  @include respond(mobile) {
    font-size: 75%; // 12px
  }
  @include respond(sm-mobile) {
    font-size: 50%; // 8px
  }
}

body {
  font-family: var(--font-roboto);
  width: 100vw;
}

a,
a:visited,
a:link,
a:focus,
a:hover {
  text-decoration: none;
}
