// 0 - 600px:         mobile
// 600 - 900px      Tablet portrait
// 900 - 1200px    Tablet landscape
// [1200 - 1800]    is where our normal styles apply
// 1800px + :         Big desktop

@mixin respond($preakpoint) {
  @if $preakpoint == sm-mobile {
    @media (max-width: 26.5em) {
      @content;
    }
  }

  @if $preakpoint == mobile {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if $preakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    }
  }
  @if $preakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    }
  }
  @if $preakpoint == big-desctop {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}
