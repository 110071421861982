.slider-container {
  position: relative;
  width: 23.375rem;
  height: 18.75rem;

  &__info {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.6rem;
    transition: opacity 1s ease-in-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.6rem;
    }

    &-detail {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 1rem 0.7rem;
      color: #fff;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.737),
        rgba(0, 0, 0, 0.089)
      );

      &__title {
        font-size: 2.1625rem;
      }

      &__subtitle {
        font-size: 1.6rem;
        margin-block: 0.5rem;
      }

      &__duration {
        width: 100%;
        display: flex;
        justify-content: center;

        div {
          width: 1.875rem;
          height: 0.125rem;
          background-color: #a7a6a6;

          &:not(last-child) {
            margin-right: 0.8rem;
          }

          &.animate-bg {
            position: relative;
            div {
              position: absolute;
              inset: 0;
              animation: increase-width 3s linear;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}
