.section {
  position: relative;

  .marques {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 5rem;
    place-items: center;
    gap: 2rem;
    position: relative;

    > img {
      height: 100%;
      width: 100%;
    }
  }

  &__duration {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 1rem;

    > div {
      width: 1.875rem;
      height: 0.125rem;
      background-color: #a7a6a6;

      &:not(last-child) {
        margin-right: 0.8rem;
      }

      &.animate-bg {
        position: relative;
        > div {
          position: absolute;
          inset: 0;
          animation: increase-width 3s linear;
          background-color: var(--primary-color);
        }
      }
    }
  }
}
