.slider {
  display: flex;
  gap: 15px;

  &-img {
    position: relative;
    width: 192px;
    height: 230px;

    &__info {
      position: absolute;
      bottom: 1rem;
      text-align: center;
      width: 100%;

      // &__title {

      // }
      // &__subtitle {

      // }
    }
  }
}

// .slider {
//   margin-bottom: 3rem;
//   position: relative;
//   height: 14.375rem;
//   width: 100%;
//   transform-style: preserve-3d;
//   animation: rotate 15s linear infinite;

//   .back {
//     position: absolute;
//     width: 400px;
//     height: 100%;
//     z-index: -9;
//     background-color: black;
//   }

//   span {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     transform-origin: center;
//     transform-style: preserve-3d;

//     img {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       border-radius: 10px;
//       object-fit: cover;
//     }
//   }
// }

// @keyframes rotate {
//   0% {
//     transform: rotateY(0deg);
//   }
//   100% {
//     transform: rotateY(360deg);
//   }
// }
