@import "./mixins";
@import "./animations";
@import "./reset";
@import "./appheader";
@import "./appnavigation";
@import "./slider";
@import "./sliderrotating";
@import "./marques";
@import "./applink";
@import "./numbers";
@import "./question";

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.text-uppercase {
  text-transform: uppercase;
}

.bg-white {
  background-color: #fff;
}

.text-white {
  color: #fff;
}

.container {
  width: 90%;
  margin: 3rem auto 0 auto;
}

.color-primary {
  color: var(--primary-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.text-white {
  color: #fff;
}

.pointer {
  cursor: pointer;
}
