.question-container {
  display: flex;
  align-items: center;
  border: 1px solid rgba($color: #000000, $alpha: 0.123);
  padding: 0.8rem 0.6rem;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  span {
    margin-left: 0.9rem;
    color: #707070;
  }
}
