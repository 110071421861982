.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  height: 5rem;
  border-bottom: 1px solid rgba($color: #000, $alpha: 0.123);

  .logo {
    font-size: 1.875rem;
    color: #707070;
    font-weight: 400;
  }

  .icon {
    width: 3rem;
    height: 3rem;
  }
}
