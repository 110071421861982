.link {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--primary-color);

  > p {
    margin-inline: 1rem;
  }
}
