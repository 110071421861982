.nav {
  background-color: #fafafa;
  box-shadow: -1px 0px 10px -3px rgba($color: #000000, $alpha: 0.2);
  border-radius: 6rem;
  display: flex;
  justify-content: space-between;

  &link {
    text-decoration: none;
    color: #909090;
    padding: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
      width: 1rem;
      height: 1rem;
    }

    &__text {
      padding-left: 0.33rem;
      font-size: 1.2rem;
    }

    &.active {
      background-color: var(--primary-color);
      border-radius: 6rem;
      color: #fff;
    }
  }
}
